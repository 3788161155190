export const Constants = {
    // BASE_URL : 'https://fxmaster-dev-apim.azure-api.net/fxmaster-api/v1/',
    // SUBSCRIPTION_KEY: "b294ef51d42f41f093c951d7ffe7bbbb",
    // FXMASTER_BASE_URL: "https://uat.fxmaster.co.uk/",
    // FILE_PATH_BASE_URL: "https://fxmaster1.blob.core.windows.net/fxmaster-uat/",
    BASE_URL: "https://fxmaster-prod-apim.azure-api.net/fxmaster-api-prod/v1/",
    SUBSCRIPTION_KEY: "83a68075ae6b41b88ee508722b29ed35",
    FXMASTER_BASE_URL: "https://fxmaster.co.uk/",
    FILE_PATH_BASE_URL: "https://fxmaster1.blob.core.windows.net/fxmaster-prod/",
    IBAN_COUNTRIES: [
        'AD','AE','AL','AT','AZ','BA','BE','BG','BL','BR','BH','CH','CR','CY','CZ','DE','DK','DO','EE','ES','FI','FO','FR','GB','GE','GF','G!','GL','GP','GR','GT','HR','HU','IE','IL','IS','IT','JO','KW','KZ','LB','LC','LI','LT','LU','LV','MC','MD','ME','MF','MK','MQ','MR','MT','MU','NC','NL','NO','PF','PK','PL','PM','PS','PT','QA','RE','RO','RS','SA','SE','SI','SM','SK','ST','TF','TN','TR','VG','WF','YT'
    ],
    BIC_COUNTRIES: ['AS','BQ','EC','FM','GU','IO','MP','PR','PW','TC','TL','UM','ZW','VI']
}