import React, { useEffect, useState } from 'react';
import { Constants } from '../lib/const/constants';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';

function SelfAccount() {
    const navigate = useNavigate();
    const location = useLocation();
    const paramsdata = location.state;
    const currency = paramsdata.currency;
    const transferflowamount = paramsdata.transferflowamount;
    const currencyid = paramsdata.currencyid;
    const balance = paramsdata.balance;
    const [loading, setLoading] = useState(false);
    const [ifscPLaceholder, setIfscPlaceholder] = useState("");
    const [bankCodePLaceholder, setbankCodePLaceholder] = useState("");

    const [firstName, setFirstName] = useState("");
    const [middleName, setMiddleName] = useState("");
    const [lastName, setLastName] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [ifscCode, setIfscCode] = useState("");
    const [bankCode, setBankCode] = useState("");
    const [city, setCity] = useState("");
    const [postCode, setPostCode] = useState("");
    const [state, setState] = useState("");
    const [address, setAddress] = useState("");
    const [beneType, setbeneType] = useState("regular");
    const [bicNumber, setbicNumber] = useState("");
    const [accountNumberPlaceholder, setaccountNumberPlaceholder] = useState("Account Number");
    const [countries, setCountries] = useState([]);
    const [beneCountry, setbeneCountry] = useState("");
    const [countrySelected, setCountrySelected] = useState("");


    const choosePLaceholder = (message) => {
        if (message == 105) {
            setIfscPlaceholder("IFSC Code");
            setbankCodePLaceholder("");
        }
        if (message == 234) {
            setIfscPlaceholder("ABA Code");
            setbankCodePLaceholder("");
        }
        if (message == 231) {
            setIfscPlaceholder("Sort Code");
            setbankCodePLaceholder("");
        }
        if (message == 38) {
            setIfscPlaceholder("Branch Code");
            setbankCodePLaceholder("Bank Code");
        }
        if (message == 13) {
            setIfscPlaceholder("BSB Number");
            setbankCodePLaceholder("");
        }
        if (message == 2) {
            setIfscPlaceholder("IBAN Number");
            setbankCodePLaceholder("BIC Number");
        }
        if (message == 55 || message == 1) {
            setIfscPlaceholder("IBAN Number");
            setbankCodePLaceholder("");
        }
    };

    const submitHandler = async () => {
        setLoading(true)
        const token = sessionStorage.getItem('login_token');
        const workspaceId = sessionStorage.getItem('login_workspaces_id');
        const defaultBank = sessionStorage.getItem('defaultBank');
        const clearBankCustomerId = sessionStorage.getItem('clearBankCustomerId');
        console.log(clearBankCustomerId);
        var obj = {};
        if (currencyid == 105) {
            obj = ({
                "workspace_id": workspaceId,
                "type": "personal",
                "first_name": firstName,
                "middle_name": middleName,
                "last_name": lastName,
                "email": "",
                "mobile": "",
                "meta": {
                    "bank_account_number": accountNumber,
                    "ifsc_code": ifscCode,
                    "bank_code_type": "sort-code",
                    "bank_account_name": firstName + " " + lastName,
                    "bank_country": 105,
                    "beneficiary_type": ["supplier", "customer"],
                    "payment_type": "regular",
                    "beneficiary_address": address,
                    "beneficiary_city": city,
                    "post_code": postCode,
                    "beneficiary_state": state,
                }
            })
        }
        else if (currencyid == 234) {
            beneType == 'regular' ?
                obj = ({
                    "workspace_id": workspaceId,
                    "type": "personal",
                    "first_name": firstName,
                    "middle_name": middleName,
                    "last_name": lastName,
                    "email": "",
                    "mobile": "",
                    "meta": {
                        "bank_account_number": accountNumber,
                        "aba_number": ifscCode,
                        "bank_code_type": "sort-code",
                        "bank_account_name": firstName + " " + lastName,
                        "bank_country": 234,
                        "country_code": beneCountry,
                        "beneficiary_type": ["supplier", "customer"],
                        "payment_type": "priority",  /// bank account number and aba in prority
                        "beneficiary_address": address,
                        "beneficiary_city": city,
                        "post_code": "NANANA",
                        "beneficiary_state": state,
                    }
                })
                :
                beneType == 'regularAcc' ?
                    obj = ({
                        "workspace_id": workspaceId,
                        "type": "personal",
                        "first_name": firstName,
                        "middle_name": middleName,
                        "last_name": lastName,
                        "email": "",
                        "mobile": "",
                        "meta": {
                            "bank_account_number": accountNumber,
                            "aba_number": ifscCode,
                            "bank_code_type": "sort-code",
                            "bank_account_name": firstName + " " + lastName,
                            "bank_country": 234,
                            "country_code": beneCountry,
                            "beneficiary_type": ["supplier", "customer"],
                            "payment_type": "priority",  /// bank account number and bic in prority
                            "beneficiary_address": address,
                            "beneficiary_city": city,
                            "post_code": "NANANA",
                            "beneficiary_state": state,
                            "bic_number": ifscCode,
                        }
                    })
                    :
                    beneType == 'completeregular' ?
                        obj = ({
                            "workspace_id": workspaceId,
                            "type": "personal",
                            "first_name": firstName,
                            "middle_name": middleName,
                            "last_name": lastName,
                            "email": "",
                            "mobile": "",
                            "meta": {
                                "bank_account_number": accountNumber,
                                "aba_number": ifscCode,
                                "bank_code_type": "sort-code",
                                "bank_account_name": firstName + " " + lastName,
                                "bank_country": 234,
                                "beneficiary_type": ["supplier", "customer"],
                                "country_code": beneCountry,
                                "payment_type": "regular", /// bank account number and aba in regular
                                "beneficiary_address": address,
                                "beneficiary_city": city,
                                "post_code": "NANANA",
                                "beneficiary_state": state,
                            }
                        })
                        :
                        obj = ({
                            "workspace_id": workspaceId,
                            "type": "personal",
                            "first_name": firstName,
                            "middle_name": middleName,
                            "last_name": lastName,
                            "email": "",
                            "mobile": "",
                            "meta": {
                                "iban": accountNumber,
                                "aba_number": bicNumber,
                                "bank_code_type": "sort-code",
                                "bank_account_name": firstName + " " + lastName,
                                "bank_country": 234,
                                "country_code": beneCountry,
                                "beneficiary_type": ["supplier", "customer"],
                                "payment_type": "priority", /// iban and bic in prority
                                "beneficiary_address": address,
                                "beneficiary_city": city,
                                "post_code": "NANANA",
                                "beneficiary_state": state,
                                "bic_number": bicNumber,
                            }
                        })

        }
        else if (currencyid == 231) {
            obj = ({
                "workspace_id": workspaceId,
                "type": "personal",
                "first_name": firstName,
                "middle_name": middleName,
                "last_name": lastName,
                "email": "",
                "mobile": "",
                "meta": {
                    "bank_account_number": accountNumber,
                    "sort_code": ifscCode,
                    "bank_code_type": "sort-code",
                    "bank_account_name": firstName + " " + lastName,
                    "bank_country": 231,
                    "beneficiary_type": ["supplier", "customer"],
                    "payment_type": "regular",
                    "beneficiary_address": address,
                    "beneficiary_city": city,
                    "post_code": postCode,
                    "beneficiary_state": state,
                }
            })
        }
        else if (currencyid == 38) {
            obj = ({
                "workspace_id": workspaceId,
                "type": "personal",
                "first_name": firstName,
                "middle_name": middleName,
                "last_name": lastName,
                "email": "",
                "mobile": "",
                "meta": {
                    "bank_account_number": accountNumber,
                    "branch_code": ifscCode,
                    "bank_code_type": "sort-code",
                    "bank_account_name": firstName + " " + lastName,
                    "bank_country": 38,
                    "beneficiary_type": ["supplier", "customer"],
                    "payment_type": "regular",
                    "beneficiary_address": address,
                    "beneficiary_city": city,
                    "post_code": "NANANA",
                    "beneficiary_state": "NANANA",
                    "bank_code": bankCode,
                }
            })
        }
        else if (currencyid == 13) {
            obj = ({
                "workspace_id": workspaceId,
                "type": "personal",
                "first_name": firstName,
                "middle_name": middleName,
                "last_name": lastName,
                "email": "",
                "mobile": "",
                "meta": {
                    "bank_account_number": accountNumber,
                    "bsb_number": ifscCode,
                    "bank_code_type": "sort-code",
                    "bank_account_name": firstName + " " + lastName,
                    "bank_country": 13,
                    "beneficiary_type": ["supplier", "customer"],
                    "payment_type": "regular",
                    "beneficiary_address": address,
                    "beneficiary_city": city,
                    "post_code": "NANANA",
                    "beneficiary_state": state,
                }
            })
        }
        else if (currencyid == 2) {
            obj = ({
                "workspace_id": workspaceId,
                "type": "personal",
                "first_name": firstName,
                "middle_name": middleName,
                "last_name": lastName,
                "email": "",
                "mobile": "",
                "meta": {
                    "bank_account_number": accountNumber,
                    "iban_number": ifscCode,
                    "bank_code_type": "sort-code",
                    "bank_account_name": firstName + " " + lastName,
                    "bank_country": 2,
                    "beneficiary_type": ["supplier", "customer"],
                    "payment_type": "priority",
                    "beneficiary_address": address,
                    "beneficiary_city": city,
                    "post_code": postCode,
                    "beneficiary_state": state,
                    "bic_number": bankCode,
                }
            })
        }
        else if (currencyid == 55 || currencyid == 1) {
            obj = ({
                "workspace_id": workspaceId,
                "type": "personal",
                "first_name": firstName,
                "middle_name": middleName,
                "last_name": lastName,
                "email": "",
                "mobile": "",
                "meta": {
                    "bank_account_number": accountNumber,
                    "iban_number": ifscCode,
                    "bank_code_type": "sort-code",
                    "bank_account_name": firstName + " " + lastName,
                    "bank_country": 1,
                    "beneficiary_type": ["supplier", "customer"],
                    "payment_type": "regular",
                    "beneficiary_address": address,
                    "beneficiary_city": city,
                    "post_code": postCode,
                    "beneficiary_state": state,
                }
            })
        }
        if (currencyid == 231 && defaultBank == 'Clear Bank as Service') {

            obj = ({
                "workspace_id": workspaceId,
                "type": "personal",
                "first_name": firstName,
                "middle_name": middleName,
                "last_name": lastName,
                "email": "",
                "mobile": "7794020107",
                "bank_account_number": accountNumber,
                "sort_code": ifscCode,
                "bank_code_type": "sort-code",
                "bank_account_name": firstName + " " + middleName + " " + lastName,
                "bank_country": 231,
                "beneficiary_type": ["supplier", "customer"],
                "payment_type": "regular",
                "beneficiary_address": "NANANA",
                "beneficiary_city": "NANANA",
                "post_code": "NANANA",
                "beneficiary_state": "NANANA",
                "customer_id": clearBankCustomerId
            })


            await axios.post(Constants.BASE_URL + "API-FX-190-CLEAR-BANK-CREATE-BENEFICIARY", obj, {
                headers: {
                    fx_key: Constants.SUBSCRIPTION_KEY,
                    Authorization: "Bearer " + JSON.parse(token)
                }
            }).then(resp => {
                // console.log(resp.data);
                resp
                alert('Beneficiary Added Successfully.');
                navigate('/SendMoney', { state: { currency: currency, transferflowamount: transferflowamount, currencyid: currencyid, balance: balance } })
                setLoading(false)
            }).catch((err) => {
                console.log(err.response.data.message);
                alert(err.response.data.message);
                setLoading(false)
            })
        }
        else {
            console.log(obj);

            axios.post(Constants.BASE_URL + "API-FX-127-AddBeneficiary", obj, {
                headers: {
                    fx_key: Constants.SUBSCRIPTION_KEY,
                    Authorization: "Bearer " + JSON.parse(token)
                }
            }).then(resp => {
                console.log(resp.data);
                //alert(resp.data.message);
                setLoading(false)
                navigate('/BeneficiaryOtpScreen', { state: { beneId: resp.data.beneficiary_id, currency: currency, transferflowamount: transferflowamount, currencyid: currencyid, balance: balance } })
            }).catch(err => {
                console.log(err.response.data);
                alert(err.response.data.message);
                setLoading(false)
            })
        }
    };

    useEffect(() => {
        getData()
    }, [])
    const getData = async () => {
        choosePLaceholder(currencyid)
        const countriesList = sessionStorage.getItem('countries');
        setCountries([JSON.parse(countriesList)])
    }



    return (
        <div className="my-2">
            <span className="text-lg font-semibold">Add Beneficiary</span>
            <div className='my-3 mx-1 flex'>
                {currencyid == 234 && countries.length > 0 &&
                    (<select
                        className="pl-8 w-1/4 p-2 outline-none rounded-2xl text-[#F1545E]"
                        onChange={(e) => {
                            setbeneCountry(e.target.value)
                            if (Constants.IBAN_COUNTRIES.includes(e.target.value)) {
                                setaccountNumberPlaceholder("IBAN Number");
                                setbeneType("priority")
                            }
                            else
                                if (Constants.BIC_COUNTRIES.includes(e.target.value)) {
                                    setaccountNumberPlaceholder("Account Number")
                                    setbeneType("regularAcc")
                                    setIfscPlaceholder('BIC Code')
                                }
                                else {
                                    setaccountNumberPlaceholder("Account Number")
                                    setbeneType("regular")
                                }
                            if (e.target.value == 'US') {
                                setCountrySelected('US')
                                setbeneType("completeregular")
                                setIfscPlaceholder('ABA Code')
                            }
                            else {
                                setCountrySelected('')
                            }
                        }}
                    >
                        <option key={0}>---Select Country---</option>
                        {countries[0].length > 0 && countries[0].map(resp => {
                            return (
                                <option key={resp.id} value={resp.code}>{resp.name}</option>
                            )
                        })}
                    </select>)}
            </div>
            {countrySelected == 'US' &&
                (<div className='my-3 mx-1 flex'>
                    <select className="pl-8 w-1/4 p-2 outline-none rounded-2xl text-[#F1545E]" onChange={(e) => {
                        if (e.target.value == 'priority') {
                            setbeneType("regular")
                            setIfscPlaceholder('ABA Code')
                        }
                        else {
                            setbeneType("completeregular")
                            setIfscPlaceholder('ABA Code')
                        }
                    }}>
                        <option value={'regular'}>Regular</option>
                        <option value={'priority'}>Priority</option>
                    </select>
                </div>)}
            <div className='my-3'>
                <input
                    placeholder='First Name'
                    className="pl-8 w-1/4 p-2 outline-none rounded-2xl text-[#F1545E] placeholder:text-sm placeholder:text-slate-700"
                    onChange={(e) => { setFirstName(e.target.value) }}
                />
            </div>
            <div className='my-3'>
                <input
                    placeholder='Middle Name'
                    className="pl-8 w-1/4 p-2 outline-none rounded-2xl text-[#F1545E] placeholder:text-sm placeholder:text-slate-700"
                    onChange={(e) => { setMiddleName(e.target.value) }}
                />
            </div>
            <div className='my-3'>
                <input
                    placeholder='Last Name'
                    className="pl-8  w-1/4 p-2 outline-none rounded-2xl text-[#F1545E] placeholder:text-sm placeholder:text-slate-700"
                    onChange={(e) => { setLastName(e.target.value) }}
                />
            </div>
            <div className='my-3'>
                {currency != 'EUR' && (
                    <input
                        placeholder={accountNumberPlaceholder}
                        className="pl-8  w-1/4 p-2 outline-none rounded-2xl text-[#F1545E] placeholder:text-sm placeholder:text-slate-700"
                        onChange={(e) => { setAccountNumber(e.target.value) }}
                    />)}
            </div>
            <div className='my-3'>
                {currencyid == '234' && beneType == 'priority' && (
                    <input
                        placeholder='BIC Code'
                        className="pl-8  w-1/4 p-2 outline-none rounded-2xl text-[#F1545E] placeholder:text-sm placeholder:text-slate-700"
                        onChange={(e) => { setbicNumber(e.target.value) }}
                    />)}
            </div>
            <div className='my-3'>
                {ifscPLaceholder != "" && beneType != 'priority' &&
                    (<input
                        placeholder={ifscPLaceholder}
                        className="pl-8  w-1/4 p-2 outline-none rounded-2xl text-[#F1545E] placeholder:text-sm placeholder:text-slate-700"
                        onChange={(e) => { setIfscCode(e.target.value) }}
                    />)}
            </div>
            <div className='my-3'>
                {bankCodePLaceholder != "" &&
                    (<input
                        placeholder={bankCodePLaceholder}
                        className="pl-8  w-1/4 p-2 outline-none rounded-2xl text-[#F1545E] placeholder:text-sm placeholder:text-slate-700"
                        onChange={(e) => { setBankCode(e.target.value) }}
                    />)}
            </div>
            <div className='my-3'>
                {ifscPLaceholder != "" && currencyid != 231 &&
                    (<input
                        placeholder='city'
                        className="pl-8  w-1/4 p-2 outline-none rounded-2xl text-[#F1545E] placeholder:text-sm placeholder:text-slate-700"
                        onChange={(e) => { setCity(e.target.value) }}
                    />)}
            </div>
            <div className='my-3'>
                {currencyid == 13 || currencyid == 234 || currencyid == 1 || currencyid == 55 &&
                    (<input
                        placeholder='Postcode'
                        className="pl-8  w-1/4 p-2 outline-none rounded-2xl text-[#F1545E] placeholder:text-sm placeholder:text-slate-700"
                        onChange={(e) => { setPostCode(e.target.value) }}
                    />)}
            </div>
            <div className='my-3'>
                {currencyid == 234 &&
                    (<input
                        placeholder='State'
                        className="pl-8  w-1/4 p-2 outline-none rounded-2xl text-[#F1545E] placeholder:text-sm placeholder:text-slate-700"
                        onChange={(e) => { setState(e.target.value) }}
                    />)}
            </div>
            <div className='my-3'>
                {ifscPLaceholder != "" && currencyid != 231 &&
                    (<input
                        placeholder='Address'
                        className="pl-8  w-1/4 p-2 outline-none rounded-2xl text-[#F1545E] placeholder:text-sm placeholder:text-slate-700"
                        onChange={(e) => { setAddress(e.target.value) }}
                    />)}
            </div>
            <div className='my-3'>
                {loading ? (
                    <div className="text-center">Processing...</div>
                )
                    :
                    <button onClick={submitHandler}
                        className="bg-[#F1545E] border border-[#F1545E] text-white px-6 py-2 rounded-lg flex items-center text-base">
                        Add
                    </button>}
            </div>
        </div>
    );
}

export default SelfAccount;